import { Component, OnInit, Inject } from '@angular/core';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface UsuarioFormData {
  usuario: Usuario;
  action: string;
}

@Component({
  selector: 'app-usuario-form',
  templateUrl: './usuario-form.component.html',
  styleUrls: ['./usuario-form.component.css'],
})
export class UsuarioFormComponent implements OnInit {
  usuario = new Usuario();
  title = '';

  Profiles = ['ROLE_ADMIN', 'ROLE_PRESTADOR', 'ROLE_GESTOR', 'ROLE_IMPORTACAO'];

  constructor(public dialogRef: MatDialogRef<UsuarioFormComponent>, @Inject(MAT_DIALOG_DATA) public data: UsuarioFormData) {
    if (data.action === 'update') {
      this.title = 'Editar';
      this.usuario = data.usuario;
    } else {
      this.title = 'Novo';
    }
  }

  ngOnInit(): void { }

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(this.usuario);
  }
}
