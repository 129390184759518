// tslint:disable: max-line-length
import { Routes } from '@angular/router';
import { AcompanhamentoContratoMainComponent } from './contratos/acompanhamento-contrato/acompanhamento-contrato-main/acompanhamento-contrato-main.component';
import { AcompanhamentoContratoDetailComponent } from './contratos/acompanhamento-contrato/acompanhamento-contrato-detail/acompanhamento-contrato-detail.component';
import { AuthGuard } from '../auth/interceptors/auth.guard';
import { AcompanhamentoClienteMainComponent } from './cliente/acompanhamento-cliente/acompanhamento-cliente-main/acompanhamento-cliente-main.component';
import { ClienteDetailComponent } from './cliente/acompanhamento-cliente/cliente-detail/cliente-detail.component';
import { UsuarioMainComponent } from './configuracao/usuario/usuario-main/usuario-main.component';

export const BaseRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'configuracao',
        children: [
          {
            path: 'usuario',
            component: UsuarioMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'contratos',
        children: [
          {
            path: 'acompanhamento-contrato',
            component: AcompanhamentoContratoMainComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-contrato-detail',
            component: AcompanhamentoContratoDetailComponent,
            canActivate: [AuthGuard],
          },
        ],
        canActivate: [AuthGuard],
      },
      {
        path: 'clientes',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'acompanhamento',
            component: AcompanhamentoClienteMainComponent,
          },
          {
            path: 'detail/:id',
            component: ClienteDetailComponent,
          },
        ],
      }
    ],
  },
];
