import { Component, OnInit, ContentChild, TemplateRef, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.css'],
})
export class CardListComponent implements OnInit {
  @Input() name: string;
  @Input() options: string[];
  @Input() menuAcoes: string[];

  @Output() atualizar = new EventEmitter<void>();
  @Output() acaoClick = new EventEmitter<string>();
  @Output() selectAll = new EventEmitter<void>();
  @Output() unselectAll = new EventEmitter<void>();

  @Output() novo = new EventEmitter<void>();
  // tslint:disable-next-line: no-any
  @ContentChild('filter', /* TODO: add static flag */ {}) filter: TemplateRef<any>;
  // tslint:disable-next-line: no-any
  @ContentChild('table', /* TODO: add static flag */ {}) table: TemplateRef<any>;
  isShowFiltros = false;

  constructor() { }

  ngOnInit(): void { }

  onAtualizar(): void {
    this.atualizar.emit();
  }

  onNovo(): void {
    this.novo.emit();
  }

  onSelectAll(): void {
    this.selectAll.emit();
  }

  onUnSelectAll(): void {
    this.unselectAll.emit();
  }

  onAcaoClick(acao: string): void {
    this.acaoClick.emit(acao);
  }
}
