import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsuarioMainComponent } from './usuario-main/usuario-main.component';
import { GlobalModule } from 'src/app/global/global.module';

@NgModule({
  declarations: [UsuarioMainComponent],
  imports: [CommonModule, GlobalModule],
})
export class UsuarioModule {}
