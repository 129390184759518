import { Resource } from 'src/app/global/domain/resource.model';

export class Contrato extends Resource {
  numCliente: string;
  nomeCliente: string;
  rota: string;
  situacao: string;
  valor: number;
  data: Date;
  dataInicio: Date;
  dataVencimento: Date;
  valorPago: number;

}
