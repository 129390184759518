import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmacao-dialog',
  templateUrl: './confirmacao-dialog.component.html',
  styleUrls: ['./confirmacao-dialog.component.css'],
})
export class ConfirmacaoDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ConfirmacaoDialogComponent>) {}
  confirm: boolean;
  ngOnInit(): void {}

  onNoClick(): void {
    this.confirm = false;
    this.dialogRef.close(this.confirm);
  }

  onConfirm(): void {
    this.confirm = true;
    this.dialogRef.close(this.confirm);
  }
}
