import { Injectable } from '@angular/core';
import { Produto } from '../domain/produto.model';
import { ResourceService } from 'src/app/global/services/resource.service';
import { HttpClient } from '@angular/common/http';
import { WEB_API } from 'src/app/global/services/web-api';
import { ProdutoSerializer } from '../serializables/produto.serializer';

@Injectable()
export class ProdutoService extends ResourceService<Produto> {
  static RESOURCE = 'produto';

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${ProdutoService.RESOURCE}`, new ProdutoSerializer(Produto));
  }
}
