import { Injectable } from '@angular/core';
import { ResourceService } from 'src/app/global/services/resource.service';
import { Posto } from '../domain/posto.model';
import { PostoSerializer } from '../serializables/posto.serializer';
import { HttpClient } from '@angular/common/http';
import { WEB_API } from 'src/app/global/services/web-api';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { Observable } from 'rxjs';
import { Page, ReturnAPI } from '../domain/return-api.model';
import { Produto } from '../domain/produto.model';

@Injectable()
export class PostoService extends ResourceService<Posto> {
  static RESOURCE = '';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, 'caixa', new PostoSerializer(Posto));
  }

  findAll(options: QueryOptions): Observable<ReturnAPI<Page<Posto>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Posto>>>(`${WEB_API}/posto?${options.toQueryString()}&${this.produtoId}`);
  }

  findPostosGeralNaoAberto(options: QueryOptions): Observable<ReturnAPI<Page<Posto>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Posto>>>(
      `${WEB_API}/posto/find-postos-geral-nao-aberto?${options.toQueryString()}&${this.produtoId}`,
    );
  }

  listarPostos(): Observable<ReturnAPI<Posto[]>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Posto[]>>(`${WEB_API}/posto/listar-postos?${this.produtoId}`);
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }
}
