import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker/datepicker-input-base';
import { Moment } from 'moment';

interface DataType {
  contratoId: number;
  valorAcordado: number;
  dataVencimento: Date;
}

@Component({
  selector: 'app-data-valor-form',
  templateUrl: './data-valor-form.component.html',
  styleUrls: ['./data-valor-form.component.css']
})
export class DataValorFormComponent implements OnInit {

  title = '';
  valor: number;
  placeholderRenegociar = 'minimo - R$ ';

  constructor (
    public dialogRef: MatDialogRef<DataValorFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataType
  ) {

  }

  ngOnInit(): void {
  }

  onConfirm(): void {
    this.dialogRef.close(Object.assign(this.data));
  }

  onPickerEvent(event: MatDatepickerInputEvent<Moment>): void {
      this.data.dataVencimento = event.value?.toDate() || null;
    }

}
