import { Component } from '@angular/core';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { SharedService } from '../shared.service';
import { UserService } from 'src/app/base/services/user.service';
import { Router } from '@angular/router';
import { MessageService } from '../../global/components/message/message.service';
import { CurrentUser } from '../../global/domain/current-user.model';
import { AppState } from 'src/app/App.state';
import { DialogService } from 'src/app/global/services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { SelecionarProdutoFormComponent } from 'src/app/global/forms/selecionar-produto-form/selecionar-produto-form.component';
import { Produto } from 'src/app/base/domain/produto.model';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  usuario = new Usuario();
  shared: SharedService;
  message: string;

  constructor(
    private userService: UserService,
    private router: Router,
    private notificador: MessageService,
    public appState: AppState,
    public dialogService: DialogService,
    public dialog: MatDialog,
  ) {
    this.shared = SharedService.getInstance();
  }

  login(): void {
    this.message = '';
    this.appState.isLoading = true;
    this.userService.login(this.usuario).subscribe(
      (userAuthentication: CurrentUser) => {
        this.appState.isLoading = false;
        this.shared.logando(userAuthentication);
        this.usuario = userAuthentication.usuario;
        this.router.navigate(['/dashboard']);
      },
      () => {
        this.appState.isLoading = false;
        this.shared.token = null;
        this.shared.usuario = null;
        this.shared.showTemplate.emit(false);
        this.notificador.messageErro('Email ou Senha Inválido!');
        this.dialogService.feedbackInfo('Email ou Senha Inválido!');
      },
    );
  }
}
