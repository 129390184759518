import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { UserService } from 'src/app/base/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from 'src/app/App.state';
import { DialogService } from 'src/app/global/services/dialog.service';
import { Page } from 'src/app/base/domain/return-api.model';
import { AcaoEvent } from 'src/app/global/lists/usuario-list/usuario-list.component';
import { UsuarioFormComponent } from 'src/app/global/forms/usuario-form/usuario-form.component';

@Component({
  selector: 'app-usuario-main',
  templateUrl: './usuario-main.component.html',
  styleUrls: ['./usuario-main.component.css'],
})
export class UsuarioMainComponent {
  private static DELETE_ACTION = 'Deletar Usuário';
  private static EDITAR_ACTION = 'Editar Usuário';

  usuarios: Usuario[];
  totalRecords = 0;

  queryOptions = new QueryOptions({ pageSize: 10, pageSort: '-id' });

  private postoSelecionado: number = null;

  constructor(private service: UserService, public dialog: MatDialog, public appState: AppState, public dialogService: DialogService) {}

  mapearAcoes(usuarios: Usuario[]): void {
    const mapearAcoes = (usuario: Usuario): Usuario => {
      const acoes = [];
      acoes.push(UsuarioMainComponent.EDITAR_ACTION);
      return { ...usuario, acoes };
    };
    this.usuarios = usuarios?.map(mapearAcoes) ?? [];
  }

  list(page: number = 0): void {
    this.queryOptions.pageNumber = page;
    this.appState.isLoading = true;
    this.service.listPageable(this.queryOptions).subscribe(
      (response: Page<Usuario>) => {
        if (response) {
          this.mapearAcoes(response.content);
          this.totalRecords = response.totalElements;
        }
        this.appState.isLoading = false;
      },
      () => (this.appState.isLoading = false),
    );
  }

  onLoad(pageNumber: number): void {
    this.list(pageNumber);
  }

  onDelete(usuario: Usuario): void {}

  onAcaoClick(event: AcaoEvent): void {
    const actions = new Map<string, (usuario: Usuario) => void>()
      .set(UsuarioMainComponent.DELETE_ACTION, (usuario: Usuario) => {
        this.dialogService.confirmDialog('Deseja realmente deletar esse usuário?', () => this.onDelete(usuario));
      })
      .set(UsuarioMainComponent.EDITAR_ACTION, (usuario: Usuario) => {
        this.onEdit(usuario);
      });

    actions.get(event.acao)(event.usuario);
  }

  onNovo(): void {
    this.dialog
      .open(UsuarioFormComponent, {
        data: {
          action: 'novo',
          usuario: null,
        },
        width: '300px',
      })
      .afterClosed()
      .subscribe((result: Usuario) => {
        if (result) {
          this.service.create(result).subscribe(
            () => {
              this.appState.isLoading = false;
              this.dialogService.feedbackSuccess('Operação realizada com sucesso!');
              this.list();
            },
            // tslint:disable-next-line: typedef
            (error) => {
              this.appState.isLoading = false;
              console.error(error);
              this.dialogService.feedbackError('Ocorreu um erro ao criar usuário!');
              this.list();
            },
          );
        }
      });
  }

  onEdit(usuario: Usuario): void {
    this.dialog
      .open(UsuarioFormComponent, {
        data: {
          action: 'update',
          usuario: usuario,
        },
        width: '300px',
      })
      .afterClosed()
      .subscribe((result: Usuario) => {
        if (result) {
          this.service.update(result).subscribe(
            () => {
              this.appState.isLoading = false;
              this.dialogService.feedbackSuccess('Operação realizada com sucesso!');
              this.list();
            },
            // tslint:disable-next-line: typedef
            (error) => {
              this.appState.isLoading = false;
              console.error(error);
              this.dialogService.feedbackError('Ocorreu um erro ao atualizar usuário!');
              this.list();
            },
          );
        }
      });
  }
}
