import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Usuario } from '../domain/usuario.model';
import { UsuarioSerializer } from '../serializables/usuario.serializer';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { Observable } from 'rxjs';

@Injectable()
export class UserService extends ResourceService<Usuario> {
  static RESOURCE = 'usuario';

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, 'usuario', new UsuarioSerializer());
  }

  public login(user: Usuario) {
    return this.http.post(`${WEB_API}/api/auth`, user);
  }
}
