import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WEB_API } from 'src/app/global/services/web-api';
import { ResourceService } from 'src/app/global/services/resource.service';
import { Contrato } from '../domain/contrato.model';
import { ContratoSerializer } from '../serializables/contrato.serializer';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { Observable } from 'rxjs';
import { ReturnAPI, Page } from '../domain/return-api.model';
import { Produto } from '../domain/produto.model';

@Injectable()
export class ContratoService extends ResourceService<Contrato> {
  static RESOURCE = 'contrato';

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${ContratoService.RESOURCE}`, new ContratoSerializer(Contrato));
  }

  findAll(queryOption: QueryOptions): Observable<Page<Contrato>> {
    return this.httpClient.get<Page<Contrato>>(
      `${WEB_API}/${ContratoService.RESOURCE}?${queryOption.toQueryString()}`,
    );
  }

  findOne(contratoID: number): Observable<ReturnAPI<Contrato>> {
    return this.httpClient.get<ReturnAPI<Contrato>>(
      `${WEB_API}/${ContratoService.RESOURCE}/findOne?contratoId=${contratoID}`,
    );
  }
  findOneValor(contrato :Contrato) : Observable<ReturnAPI<any>> {
  
      return this.httpClient.post<ReturnAPI<Contrato>>(
       `${WEB_API}/${ContratoService.RESOURCE}/calcular-juros`,contrato
     );
  }


}
