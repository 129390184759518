import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-box-tools',
  templateUrl: './card-box-tools.component.html',
  styleUrls: ['./card-box-tools.component.css']
})
export class CardBoxToolsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
