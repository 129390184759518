import { Routes } from '@angular/router';

import { ButtonsComponent } from './buttons/buttons.component';
import { GridSystemComponent } from './grid/grid.component';
import { IconsComponent } from './icons/icons.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PanelsComponent } from './panels/panels.component';
import { TypographyComponent } from './typography/typography.component';
import { LoginComponent } from '../../auth/login/login.component';

export const ComponentsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'buttons',
        component: ButtonsComponent,
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: 'grid',
        component: GridSystemComponent,
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: 'icons',
        component: IconsComponent,
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: 'notifications',
        component: NotificationsComponent,
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: 'panels',
        component: PanelsComponent,
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: 'typography',
        component: TypographyComponent,
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
    ],
  },
];
