import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CardListComponent } from '../../components/card-list/card-list.component';
import { NgForm } from '@angular/forms';
import { Table } from 'primeng/table';
import { Contrato } from 'src/app/base/domain/contrato.model';
import { LazyLoadEvent } from 'primeng/api';
import { Posto } from 'src/app/base/domain/posto.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  CheckboxSelectFormComponent,
  CheckboxSelectFormData,
  CheckboxSelectFormResultData,
} from '../../forms/checkbox-select-form/checkbox-select-form.component';
import { DialogService } from '../../services/dialog.service';
import { SituacaoContrato } from 'src/app/base/domain/enum/situacao-contrato.enum';

export interface ContratoAcaoEvent {
  acao: string;
  contrato: Contrato;
}

export interface ContratoListFilterOptions {
  nomeCliente: string;
  apelidoCliente: string;
  numCliente: string;
  descricaoRota: string;
  dataInicial: Date;
  dataFinal: Date;
  cpf: string;
}

@Component({
  selector: 'app-contrato-list',
  templateUrl: './contrato-list.component.html',
  styleUrls: ['./contrato-list.component.css'],
})
export class ContratoListComponent implements OnInit {
  @ViewChild('searchForm', { static: false }) searchForm: NgForm;
  @ViewChild('dataTable', { static: false }) dataTable: Table;
  @ViewChild(CardListComponent, { static: true }) cardList: CardListComponent;

  @Input() contratos: Contrato[];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() loading: boolean;
  @Input() title: string;
  @Input() nomePlaceholder: string;
  @Input() dataPlaceholder: string;
  @Input() filtros: string[];
  @Input() options: string[];
  @Input() optionalColumns: string[];
  @Input() menuAcoes: string[];

  @Output() loadContratosPage = new EventEmitter<number>();
  @Output() disclosureContrato = new EventEmitter<Contrato>();
  @Output() acaoClick = new EventEmitter<ContratoAcaoEvent>();
  @Output() filter = new EventEmitter<ContratoListFilterOptions>();
  @Output() cancelarContratos = new EventEmitter<Contrato[]>();
  @Output() transferirContratos = new EventEmitter<Contrato[]>();
  @Output() transferirCobrancas = new EventEmitter<Contrato[]>();
  @Output() exportarLote = new EventEmitter<Contrato[]>();
  @Output() selectAllContratos = new EventEmitter<boolean>();
  @Output() visualizarLote = new EventEmitter<boolean>();
  @Output() openModalContrato = new EventEmitter<Contrato>();

  private currentPage = 0;
  isAllContratoSelected: boolean = false;

  situacoesContrato: SituacaoContrato[] = Object.values(SituacaoContrato);

  contratosSelecionados: Contrato[] = [];

  private readonly defaultFilterOptions: ContratoListFilterOptions = {
    nomeCliente: '',
    apelidoCliente: '',
    numCliente: '',
    dataInicial: null,
    dataFinal: null,
    descricaoRota: '',
    cpf: '',
  };

  filterOptions: ContratoListFilterOptions;

  constructor(public dialog: MatDialog, public dialogService: DialogService) { }

  ngOnInit(): void {
    this.onLimparFiltros();
  }

  loadContratos(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadContratosPage.emit(pageNumber);
  }

  onDisclosure(contrato: Contrato): void {
    this.disclosureContrato.emit(contrato);
  }

  openContratoModal(contrato: Contrato): void {
    this.openModalContrato.emit(contrato);
  }

  onAcaoClick(acao: string, domain: Contrato): void {
    this.acaoClick.emit({ acao, contrato: domain });
  }

  onAtualizar(): void {
    this.loadContratosPage.emit(this.currentPage);
  }

  onLimparFiltros(): void {
    if (this.filterOptions) {
      this.filterOptions.dataInicial = null;
      this.filterOptions.dataFinal = null;
    }
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  onCheckboxChange($event: MatCheckboxChange): void {
    this.contratosSelecionados = this.contratos.filter((contrato: Contrato) => contrato.selecionado).map((contrato: Contrato) => contrato);
    if (this.isAllContratoSelected) {
      this.isAllContratoSelected = false;
      this.selectAllContratos.emit(false);
    }
  }

  selectAll(): void {
    this.contratos.forEach((element: Contrato) => {
      element.selecionado = true;
    });

    this.contratosSelecionados = this.contratos.filter((contrato: Contrato) => contrato.selecionado).map((contrato: Contrato) => contrato);

    this.isAllContratoSelected = true;
    this.selectAllContratos.emit(true);
  }

  unselectAll(): void {
    this.contratos.forEach((element: Contrato) => {
      element.selecionado = false;
    });

    this.contratosSelecionados = [];

    this.isAllContratoSelected = false;
    this.selectAllContratos.emit(false);
  }

  public onMenuAcaoClick(acao: string): void {
    if (acao === 'Cancelar Contratos Selecionados') {
      this.cancelarContratos.emit(this.contratosSelecionados);
    } else if (acao === 'Transferir Responsavel') {
      this.transferirContratos.emit(this.contratosSelecionados);
    } else if (acao === 'Transferir Cobranças') {
      this.transferirCobrancas.emit(this.contratosSelecionados);
    } else if (acao === 'Exportar Lote') {
      this.exportarLote.emit(this.contratosSelecionados);
    } else if (acao === 'Visualizar Lote') {
      this.visualizarLote.emit(true);
    }
  }
}
