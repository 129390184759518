import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Parcela } from 'src/app/base/domain/parcela.model';

@Component({
  selector: 'app-parcela-list',
  templateUrl: './parcela-list.component.html',
  styleUrls: ['./parcela-list.component.css']
})
export class ParcelaListComponent implements OnInit {

  @Input() title: string;
  @Input() nomePlaceholder: string;
  @Input() parcelas: Parcela[];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() loading: boolean;
  @Input() filtros: string[];
  @Input() options: string[];
  @Input() optionalColumns: string[];

  @Output() loadParcelasPage = new EventEmitter<number>();

  private currentPage = 0;

  constructor() {}

  ngOnInit(): void {}

  load(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadParcelasPage.emit(pageNumber);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

}
