import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IBarChartOptions, IResponsiveOptionTuple, Bar, IChartistData, IChartistBarChart } from 'chartist';

@Component({
  selector: 'app-grafico-vertical',
  templateUrl: './grafico-vertical.component.html',
  styleUrls: ['./grafico-vertical.component.css'],
})
export class GraficoVerticalComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() labels: string[];
  @Input() series: number[][];
  @Input() height: string;

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.configurarGraficoClientes();
  }

  private configurarGraficoClientes(): void {
    const dataMultipleBarsChart: IChartistData = {
      labels: this.labels,
      series: this.series,
    };

    const optionsMultipleBarsChart: IBarChartOptions = {
      seriesBarDistance: 10,
      axisX: {
        showGrid: false,
      },
      height: this.height || '300px',
    };

    const responsiveOptionsMultipleBarsChart: IResponsiveOptionTuple<IBarChartOptions>[] = [
      [
        'screen and (max-width: 640px)',
        {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc: (value: number[]) => value[0],
          },
        },
      ],
    ];

    const chart = new Bar('#multipleBarsChart', dataMultipleBarsChart, optionsMultipleBarsChart, responsiveOptionsMultipleBarsChart);
    this.startAnimationForBarChart(chart);
  }

  private startAnimationForBarChart(chart: IChartistBarChart): void {
    let seq2: number, delays2: number, durations2: number;
    seq2 = 0;
    delays2 = 80;
    durations2 = 300;
    // tslint:disable-next-line: no-any
    chart.on('draw', function (data: any): void {
      if (data.type === 'bar') {
        seq2++;
        data.element.animate({
          opacity: {
            begin: seq2 * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: 'ease',
          },
        });
      }
    });
    seq2 = 0;
  }
}
