import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { EstadoService } from './services/estado.service';
import { PessoaService } from './services/pessoa.service';
import { UserService } from './services/user.service';
import { BaseRoutes } from './base.routing';
import { PostoService } from './services/posto.service';
import { AcompanhamentoContratoModule } from './contratos/acompanhamento-contrato/acompanhamento-contrato.module';
import { ContratoService } from './services/contrato.service';
import { AcompanhamentoClienteModule } from './cliente/acompanhamento-cliente/acompanhamento-cliente.module';
import { ClienteService } from './services/cliente.service';
import { ProdutoModule } from './configuracao/produto/produto.module';
import { UsuarioModule } from './configuracao/usuario/usuario.module';
import { ParcelaService } from './services/parcela.service';
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(BaseRoutes),
    AcompanhamentoContratoModule,
    AcompanhamentoClienteModule,
    ProdutoModule,
    UsuarioModule,
  ],
  providers: [
    EstadoService,
    PessoaService,
    UserService,
    ContratoService,
    PostoService,
    ClienteService,
    ParcelaService,
  ],
  exports: [],
  declarations: [],
})
export class BaseModule { }
