import { Component, OnInit } from '@angular/core';
import { SharedService } from './auth/shared.service';
import { AppState } from './App.state';

@Component({
  selector: 'app-my-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public shared: SharedService;

  showTemplate = false;
  loading = false;

  constructor() {
    this.shared = SharedService.getInstance();
    if (this.shared.isLoggedIn()) {
      this.showTemplate = true;
    }
  }

  ngOnInit(): void {
    this.shared.showTemplate.subscribe((show: boolean) => (this.showTemplate = show));
    AppState.getInstance().showLoading.subscribe((show: boolean) => {
      this.loading = show;
    });
  }
}
