import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-select-form',
  templateUrl: './select-form.component.html',
  styleUrls: ['./select-form.component.css'],
})
export class SelectFormComponent implements OnInit {
  _listInput: string[];

  get listInput() {
    return this._listInput;
  }

  @Input() set listInput(value: string[]) {
    this._listInput = value;
    this.selected = null;
    this.selectableInput = '';
    this.selectableFilteredOptions = this.listInput;
  }

  selectableFilteredOptions: string[] = [];
  selectableInput = '';

  constructor(public dialogRef: MatDialogRef<SelectFormComponent>) {}

  ngOnInit(): void {
    this.selectableFilteredOptions = this.listInput;
  }

  optionSelected?: string = null;
  @Input() placeholder: string;
  @Output() selectedChange = new EventEmitter<string>();
  @Output() onSelectedEvent = new EventEmitter<string>();

  @Input()
  get selected(): string {
    return this.optionSelected;
  }

  set selected(val: string) {
    this.optionSelected = val;
    this.selectedChange.emit(this.optionSelected);
  }

  onSelect(value: string): void {
    this.selected = value;
    this.onSelectedEvent.emit(value);
  }

  async doFilter(): Promise<void> {
    this.selectableFilteredOptions = await this._filtrar(this.selectableInput);
  }

  private async _filtrar(value: string): Promise<string[]> {
    const filterValue = value?.toLowerCase();
    return this.listInput.filter((e: string) => e.toLowerCase().indexOf(filterValue) === 0);
  }
}
