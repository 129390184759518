import { query } from 'chartist';

export interface QueryBuilder {
  toQueryMap: () => Map<string, string>;
  toQueryString: () => string;
}

export class QueryOptions implements QueryBuilder {
  public pageNumber: number;
  public pageSize: number;
  public pageSort: string;
  public search: string;

  params: { [key: string]: string | number | boolean } = {};

  constructor(options?: Partial<QueryOptions>) {
    this.pageNumber = options?.pageNumber ?? 0;
    this.pageSize = options?.pageSize ?? 10000;
    this.pageSort = options?.pageSort ?? 'id';
    this.search = options?.search ?? '';
    this.params = options?.params;
  }

  toQueryMap(withDefaultOptions: boolean = true): Map<string, string> {
    const queryMap = new Map<string, string>();

    if (withDefaultOptions) {
      queryMap.set('page', `${this.pageNumber}`);
      queryMap.set('size', `${this.pageSize}`);
      queryMap.set('sort', `${this.pageSort}`);
      queryMap.set('', `${this.search}`);
    }

    if (this.params) {
      Object.entries(this.params).forEach(([key, value]: [string, string]) => {
        if (value != null) {
          queryMap.set(key, value);
        }
      });
    }

    return queryMap;
  }

  toQueryString(withDefaultOptions: boolean = true): string {
    let queryString = '';
    this.toQueryMap(withDefaultOptions).forEach((value: string, key: string) => {
      queryString = queryString.concat(key === '' ? `${value}&` : `${key}=${value}&`);
    });

    return queryString.substring(0, queryString.length - 1);
  }
}
