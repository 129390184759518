import { Resource } from "src/app/global/domain/resource.model";
import { Produto } from "./produto.model";

export class Estado extends Resource {
    public ativo: boolean;
    public id: number;
    public nome: string;
    public sigla: string;
    public produtos: Produto[];
  
    constructor() {
      super();
    }
}
  