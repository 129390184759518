import { ResourceService } from 'src/app/global/services/resource.service';
import { Cliente } from '../domain/cliente.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WEB_API } from 'src/app/global/services/web-api';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { Observable } from 'rxjs';
import { ClienteSerializer } from '../serializables/cliente.serializer';
import { Produto } from '../domain/produto.model';
import { Page } from '../domain/return-api.model';

@Injectable()
export class ClienteService extends ResourceService<Cliente> {
  static RESOURCE = 'cliente';

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, ClienteService.RESOURCE, new ClienteSerializer(Cliente));
  }

  findAll(queryOption: QueryOptions): Observable<Page<Cliente>> {
    return this.httpClient.get<Page<Cliente>>(
      `${WEB_API}/${ClienteService.RESOURCE}?${queryOption.toQueryString()}`,
    );
  }
}
