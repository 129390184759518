import { Input, Component } from '@angular/core';

@Component({
  selector: 'app-counter-card',
  templateUrl: './counter-card.component.html',
  styleUrls: ['./counter-card.component.css'],
})
export class CounterCardComponent {
  @Input() headerClass: string;
  @Input() icon: string;
  @Input() descIcon: string;
  @Input() subDescIcon: string;
  @Input() title: string;
  @Input() description: string;
  @Input() subDescription: string;
  @Input() counter: number;
}
