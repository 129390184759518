import { Resource } from 'src/app/global/domain/resource.model';
import { Estado } from './estado.model';

export class Produto extends Resource {
  public descricao: string;
  public url: string;
  public isHomol: boolean;
  public estado: Estado;

  constructor() {
    super();
  }
}
