import { Injectable } from '@angular/core';
import { ReturnAPI, MessageAPI, Page } from 'src/app/base/domain/return-api.model';
import { MatDialog } from '@angular/material/dialog';
import swal from 'sweetalert2';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { SharedService } from 'src/app/auth/shared.service';
import { CurrentUser } from '../domain/current-user.model';
import { UserService } from 'src/app/base/services/user.service';
import { AppState } from 'src/app/App.state';
import { MessageService } from '../components/message/message.service';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  shared: SharedService;

  constructor(
    public _matDialog: MatDialog,
    private userService: UserService,
    public appState: AppState,
    private notificador: MessageService,
  ) {
    this.shared = SharedService.getInstance();
  }

  feedbackReturnAPI<T>(returnApi: ReturnAPI<T>, mensagemSucesso: string, afterClosed?: (success: boolean) => void): boolean {
    if (returnApi.success) {
      this.feedbackSuccess(mensagemSucesso, afterClosed);
      return true;
    } else if (returnApi.messages?.length > 0) {
      this.feedbackInfo(returnApi.messages.reduce((prev: string, msg: MessageAPI) => `${prev}\n${msg.text}`, ''));
    } else {
      this.feedbackError('Houve um erro ao executar a operação!');
    }

    return false;
  }

  async feedbackSuccess(message: string, afterClosed?: (success: boolean) => void): Promise<void> {
    await swal('Sucesso', message, 'success');
    if (afterClosed) {
      afterClosed(true);
    }
  }

  async feedbackInfo(message: string, afterClosed?: (success: boolean) => void): Promise<void> {
    await swal('Info', `${message}\n\n:: ${new Date().toLocaleString('pt-BR')}`, 'info');
    if (afterClosed) {
      afterClosed(false);
    }
  }

  async feedback(message: string, afterClosed?: (success: boolean) => void): Promise<void> {
    await swal('Info', `${message}`);
    if (afterClosed) {
      afterClosed(false);
    }
  }

  async feedbackError(message: string, afterClosed?: (success: boolean) => void): Promise<void> {
    await swal('Error', `${message}\n\n:: ${new Date().toLocaleString('pt-BR')}`, 'error');
    if (afterClosed) {
      afterClosed(false);
    }
  }

  async confirmDialog(message: string, confirmfn?: () => void, cancelfn?: () => void): Promise<boolean> {
    const result = await swal({
      title: 'Confirmar Operação',
      text: message,
      type: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Confirmar',
    });

    const success: boolean = result.value ?? false;

    if (success && confirmfn) {
      confirmfn();
    } else if (!success && cancelfn) {
      cancelfn();
    }

    return success;
  }

  async questionDialog(title: string, message?: string, confirmfn?: () => void, cancelfn?: () => void): Promise<boolean> {
    const result = await swal({
      title,
      text: message,
      type: 'question',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Não',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim',
    });

    const success: boolean = result.value ?? false;

    if (success && confirmfn) {
      confirmfn();
    } else if (!success && cancelfn) {
      cancelfn();
    }

    return success;
  }

  async selectDialog<T>(
    title: string,
    selectOptions: T[],
    confirmfn?: (selected: T, index?: number) => string,
    cancelfn?: () => void,
  ): Promise<[T, number]> {
    const result = await swal({
      title,
      input: 'select',
      inputOptions: selectOptions.reduce((options: {}, o: T, i: number) => {
        options[i.toString()] = o;
        return options;
      }, {}),
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    });

    const index: number = result.value ? parseInt(result.value, 10) : -1;
    const valueResult: [T, number] = [index > -1 ? selectOptions[index] : null, index];

    if (index && confirmfn) {
      confirmfn(valueResult[0], valueResult[1]);
    } else if (cancelfn) {
      cancelfn();
    }

    return valueResult;
  }

  async parecerDialog(texto: string, confirmfn: (response: string) => string, cancelfn?: () => void): Promise<void> {
    const usuario = new Usuario();
    usuario.email = this.shared.usuario.email;

    const result = await swal({
      text: texto,
      input: 'textarea',
      inputPlaceholder: 'Digite o parecer aqui...',
      inputAttributes: {
        'aria-label': 'Type your message here',
      },
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Confirmar',
    });

    if (result.value) {
      confirmfn(result.value);
    } else if (cancelfn) {
      cancelfn();
    }
  }

  async auditoriaDialog(confirmfn: () => void, cancelfn?: () => void): Promise<void> {
    const usuario = new Usuario();
    usuario.email = this.shared.usuario.email;

    const result = await swal({
      title: 'Confirmar Operação',
      text: 'Informe a sua senha para que essa operação seja realizada e registrada.',
      input: 'password',
      inputPlaceholder: 'senha',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Confirmar',
    });

    if (result.value) {
      usuario.password = result.value;
      this.appState.isLoading = true;
      this.userService.login(usuario).subscribe(
        (userAuthentication: CurrentUser) => {
          this.appState.isLoading = false;
          this.shared.logando(userAuthentication);
          confirmfn();
        },
        () => {
          this.appState.isLoading = false;
          this.shared.showTemplate.emit(false);
          this.notificador.messageErro('Email ou Senha Inválido!');
          this.feedbackInfo('Email ou Senha Inválido!');
          cancelfn();
        },
      );
    } else if (cancelfn) {
      cancelfn();
    }
  }
}
