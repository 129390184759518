import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class AppState {
  private static instance: AppState = null;

  private _isLoading: boolean;
  set isLoading(v: boolean) {
    this._isLoading = v;
    this.showLoading.emit(this._isLoading);
  }

  showLoading = new EventEmitter<boolean>();

  constructor() {
    return (AppState.instance = AppState.instance || this);
  }

  public static getInstance(): AppState {
    if (this.instance === null) {
      this.instance = new AppState();
    }
    return this.instance;
  }
}
