import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Contrato } from '../../../domain/contrato.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ContratoService } from '../../../services/contrato.service';
import { DialogService } from 'src/app/global/services/dialog.service';
import { ContratoAcaoEvent, ContratoListFilterOptions } from 'src/app/global/lists/contrato-list/contrato-list.component';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from 'src/app/auth/shared.service';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { AppState } from 'src/app/App.state';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';

@Component({
  selector: 'app-acompanhamento-contrato-main',
  templateUrl: './acompanhamento-contrato-main.component.html',
  styleUrls: ['./acompanhamento-contrato-main.component.css'],
})
export class AcompanhamentoContratoMainComponent implements OnInit {
  contratos: Contrato[] = [];
  menuAcoes: string[] = [];

  shared: SharedService;
  usuario: Usuario;

  queryOptions = new QueryOptions({ pageSize: 25 });
  totalRecords = 0;
  loading = false;
  totalizadorBruto: number;
  totalizadorLiquido: number;

  constructor(
    private router: Router,
    private contratoService: ContratoService,
    public dialog: MatDialog,
    public appState: AppState,
    public dialogService: DialogService,
  ) {
    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;
  }

  ngOnInit(): void { }

  listarContratos(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber;
    this.loading = true;
    this.contratoService.findAll(this.queryOptions).subscribe((response: Page<Contrato>) => {
      this.contratos = response.content;
      this.totalRecords = response.totalElements;
      this.loading = false;
    });
  }

  openContratoModal(contrato: Contrato): void {
    this.contratoService.findOneValor(contrato).subscribe((retorno: ReturnAPI<Contrato>) => {
    this.dialogService.feedback(`O valor do juros do contrato: R$ ${retorno}`);
  })
  };
    
  onAcaoClick(event: ContratoAcaoEvent): void {
    const actions = new Map<string, (contrato: Contrato) => void>();
    actions.get(event.acao)(event.contrato);
  }


  onContratosPage(page: number): void {
    this.listarContratos(page);
  }

  onDisclosure(contrato: Contrato): void {
    this.router.navigate(['base/contratos/acompanhamento-contrato-detail'], 
      {queryParams: {numCliente: contrato.numCliente, data: contrato.data}});
  }

  onFilter(options: ContratoListFilterOptions): void {
    this.queryOptions.params = {
      nomeCliente: options.nomeCliente ? `${options.nomeCliente}` : null,
      apelidoCliente:  options.apelidoCliente ? `${options.apelidoCliente}` : null,
      numCliente: options.numCliente ? `${options.numCliente}` : null,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
      descricaoRota: options.descricaoRota || null,
      cpf: options.cpf || null
    };

    this.listarContratos(0);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
