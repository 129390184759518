import { Resource } from 'src/app/global/domain/resource.model';

export class Posto extends Resource {
  public descricao: string;

  public uid: string;

  constructor() {
    super();
  }
}
