import { AfterViewChecked, Component, Input, OnInit } from '@angular/core';
import { Contrato } from 'src/app/base/domain/contrato.model';
import { MatDialog } from '@angular/material/dialog';
import { ReturnAPI } from 'src/app/base/domain/return-api.model';
import { DialogService } from '../../services/dialog.service';
import { DataValorFormComponent } from '../../forms/data-valor-form/data-valor-form.component';
import { ContratoService } from '../../../base/services/contrato.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/auth/shared.service';
import { Usuario } from 'src/app/base/domain/usuario.model';

type ContratoAction = [string, string];

@Component({
  selector: 'app-contrato-info-geral',
  templateUrl: './contrato-info-geral.component.html',
  styleUrls: ['./contrato-info-geral.component.css'],
})
export class ContratoInfoGeralComponent implements OnInit, AfterViewChecked {
  private static ALTERAR_TITULACAO_ACTION = 'Alterar Endereço';
  private static RENEGOCIAR_CONTRATO = 'Renegociar Divida';
  private static REPACTUAR_DIVIDA = 'Repactuar Divida';
  private static LIQUIDAR_CONTRATO = 'Liquidar Contrato';

  @Input() contrato: Contrato;

  valorPago = 0;
  diasEmAtraso: number;
  contratoId: number;

  shared: SharedService;
  usuario: Usuario;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public dialogService: DialogService,
    private contratoService: ContratoService,
  ) {
    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;
   }

  contratoActions: ContratoAction[] = [
  ];

  ngOnInit(): void { }

  ngAfterViewChecked(): void {

    const hoje = new Date().setHours(0, 0, 0);
    const contrato = new Date(this.contrato.data).setHours(0, 0, 0);

    this.contratoId = this.contrato.id;

    this.diasEmAtraso = Math.floor((hoje - contrato) / 1000 / 60 / 60 / 24);
  }

  
  onAction(action: ContratoAction): void {
    const actions = new Map<string, () => void>();
    actions.get(action[0])();
  }
  
  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
