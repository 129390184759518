import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { SharedService } from 'src/app/auth/shared.service';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { Router } from '@angular/router';

// tslint:disable-next-line: no-any
declare const $: any;

// Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
  profiles?: string[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
  profiles?: string[];
}

// Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard',
  },
  {
    path: '/base/contratos',
    title: 'Contratos',
    type: 'sub',
    icontype: 'list_alt',
    collapse: 'Contratos',
    children: [
      { path: 'acompanhamento-contrato', title: 'Acompanhamento', ab: 'A', profiles: ['ROLE_GESTOR', 'ROLE_PRESTADOR'] },
    ],
    profiles: ['ROLE_GESTOR', 'ROLE_PRESTADOR'],
  },
  {
    path: '/base/clientes',
    title: 'Clientes',
    type: 'sub',
    icontype: 'face',
    collapse: 'clientes',
    children: [
      { path: 'acompanhamento', title: 'Acompanhamento', ab: 'AC', profiles: ['ROLE_GESTOR', 'ROLE_PRESTADOR'] },
    ],
    profiles: ['ROLE_GESTOR', 'ROLE_PRESTADOR'],
  },
  {
    path: '/base/configuracao',
    title: 'Configurações',
    type: 'sub',
    icontype: 'build',
    collapse: 'configuracao',
    children: [
      { path: 'usuario', title: 'Usuário', ab: 'U' },
    ],
    profiles: ['ROLE_ADMIN'],
  },
];

@Component({
  selector: 'app-sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  // tslint:disable-next-line: no-any
  public menuItems: any[];
  // tslint:disable-next-line: no-any
  ps: any;

  shared: SharedService;

  usuario: Usuario;

  isMobileMenu(): boolean {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  constructor(private router: Router) {
    this.shared = SharedService.getInstance();
  }

  ngOnInit(): void {
    // tslint:disable-next-line: no-any
    this.menuItems = ROUTES.filter((menuItem: any) => menuItem);
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
      this.ps = new PerfectScrollbar(elemSidebar);
    }

    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;
  }
  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.ps.update();
    }
  }
  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }

  logout(): void {
    this.shared.logout();
    this.router.navigate(['/login']);
  }
}
