import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { Cliente } from 'src/app/base/domain/cliente.model';
import { LazyLoadEvent } from 'primeng/api';
import { Posto } from 'src/app/base/domain/posto.model';
import { SharedService } from 'src/app/auth/shared.service';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { SituacaoCliente } from 'src/app/base/domain/enum/situacao-cliente.enum';

export interface ClienteAcaoEvent {
  acao: string;
  cliente: Cliente;
}
export interface ClienteListFilterOptions {
  nomeCliente: string;
  apelidoCliente: String;
  numCliente: String;
  dataInicial: Date;
  dataFinal: Date;
  descricaoRota: string;
  cpf: string;
}

@Component({
  selector: 'app-cliente-list',
  templateUrl: './cliente-list.component.html',
  styleUrls: ['./cliente-list.component.css'],
})
export class ClienteListComponent implements OnInit {
  @Input() title: string;
  @Input() nomePlaceholder: string;
  @Input() clientes: Cliente[];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() loading: boolean;
  @Input() filtros: string[];
  @Input() options: string[];
  @Input() optionalColumns: string[];

  shared: SharedService;
  usuario: Usuario;

  @Output() loadClientesPage = new EventEmitter<number>();
  @Output() filter = new EventEmitter<ClienteListFilterOptions>();
  @Output() acaoClick = new EventEmitter<ClienteAcaoEvent>();
  @Output() disclosureCliente = new EventEmitter<Cliente>();

  private currentPage = 0;

  private readonly defaultFilterOptions: ClienteListFilterOptions = {
    nomeCliente: '',
    apelidoCliente: '',
    numCliente: '',
    dataInicial: null,
    dataFinal: null,
    descricaoRota: '',
    cpf: '',
  };
  filterOptions: ClienteListFilterOptions;

  situacoes: SituacaoCliente[] = Object.values(SituacaoCliente);

  constructor() {
    this.onLimparFiltros();
    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;
  }

  ngOnInit(): void {}

  loadClientes(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadClientesPage.emit(pageNumber);
  }

  onAcaoClick(acao: string, domain: Cliente): void {
    this.acaoClick.emit({ acao, cliente: domain });
  }

  onAtualizar(): void {
    this.loadClientesPage.emit(this.currentPage);
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  onDisclosure(cliente: Cliente): void {
    this.disclosureCliente.emit(cliente);
  }
}
