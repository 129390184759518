import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthGuard } from './auth/interceptors/auth.guard';

export const AppRoutes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '**',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: '',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'base',
        loadChildren: './base/base.module#BaseModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'components',
        loadChildren: './global/components/components.module#ComponentsModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'forms',
        loadChildren: './global/forms/forms.module#Forms',
        canActivate: [AuthGuard],
      },
      {
        path: 'tables',
        loadChildren: './global/tables/tables.module#TablesModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'maps',
        loadChildren: './global/maps/maps.module#MapsModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'widgets',
        loadChildren: './global/widgets/widgets.module#WidgetsModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'charts',
        loadChildren: './global/charts/charts.module#ChartsModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'calendar',
        loadChildren: './global/calendar/calendar.module#CalendarModule',
        canActivate: [AuthGuard],
      },
    ],
  },
];
