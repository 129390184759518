import { Resource } from 'src/app/global/domain/resource.model';
import { Usuario } from './usuario.model';

export class Pessoa extends Resource {
  public assignedUser: Usuario;
  public user: Usuario;
  public ativo: boolean;
  public dataNascimento: Date;
  public date: string;
  public documento: string;
  public email: string;
  public name: string;
  public rg: string;
  public tipoPessoa: string;
  public nomePessoa: string;
  public identificador: string;
  public nomeFantasiaPessoa: string;
  public type: 'pf' | 'pj';

  constructor() {
    super();
  }
}
