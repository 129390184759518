import { Component, OnInit, forwardRef, Input, ContentChild, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, CheckboxControlValueAccessor, NgModel } from '@angular/forms';

@Component({
  selector: 'app-humanus-checkbox-sn',
  templateUrl: './checkbox-sn.component.html',
  styleUrls: ['./checkbox-sn.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxSnComponent),
      multi: true
    }
  ]
})
export class CheckboxSnComponent implements OnInit, ControlValueAccessor {

  @Input() label: string;
  value: boolean;
  onChange: any;
  idComp: number;
  comp: any;
  checked = false;

  constructor() {
    this.idComp = Math.random();
  }

  isChecked() {
    return this.checked;
  }

  ngOnInit() {
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void { }

  setDisabledState?(isDisabled: boolean): void {
  }
}
