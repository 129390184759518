import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pessoa } from '../domain/pessoa.model';
import { PessoaSerializer } from '../serializables/pessoa.serializer';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';

@Injectable()
export class PessoaService extends ResourceService<Pessoa> {

    static RESOURCE: any = '';

    constructor(private http: HttpClient) {
        super(http, `${WEB_API}`, 'pessoa', new PessoaSerializer(Pessoa));
    }

    public buscarPorCNPJ(cnpj: number): Observable<Pessoa> {
        return this.httpClient
          .get(`${this.url}/${this.endpoint}/buscar?cnpj=${cnpj}`)
          .map((data: any) => this.serializer.fromJson(data) as Pessoa);
    }

    public listbyPessoa(id: number): Observable<Pessoa[]> {
        return this.httpClient
          .get(`${this.url}/${this.endpoint}/pessoa/${id}`)
          .map((data: any) => this.convertData(data));
    }
}
