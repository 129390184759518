import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResourceService } from "src/app/global/services/resource.service";
import { WEB_API } from "src/app/global/services/web-api";
import { Estado } from "../domain/estado.model";
import { EstadoSerializer } from "../serializables/estado.serializer";

@Injectable()
export class EstadoService extends ResourceService<Estado> {
  static RESOURCE = 'estado';

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${EstadoService.RESOURCE}`, new EstadoSerializer(Estado));
  }
}
