import { Component, OnInit } from '@angular/core';
import { Produto } from 'src/app/base/domain/produto.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ProdutoService } from 'src/app/base/services/produto.service';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from 'src/app/App.state';
import { DialogService } from 'src/app/global/services/dialog.service';
import { AcaoEvent, ListFilterOptions } from 'src/app/global/lists/produto-list/produto-list.component';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';

@Component({
  selector: 'app-produto-main',
  templateUrl: './produto-main.component.html',
  styleUrls: ['./produto-main.component.css'],
})
export class ProdutoMainComponent {
  private static DELETE_ACTION = 'Deletar produto';
  private static EDITAR_ACTION = 'Editar produto';

  produtos: Produto[];
  totalRecords = 0;

  queryOptions = new QueryOptions({ pageSize: 10, pageSort: '-id' });

  private postoSelecionado: number = null;

  constructor(private service: ProdutoService, public dialog: MatDialog, public appState: AppState, public dialogService: DialogService) {}

  mapearAcoes(produtos: Produto[]): void {
    const mapearAcoes = (produto: Produto): Produto => {
      const acoes = [];
      acoes.push(ProdutoMainComponent.EDITAR_ACTION);
      return { ...produto, acoes };
    };
    this.produtos = produtos?.map(mapearAcoes) ?? [];
  }

  list(page: number = 0): void {
    this.queryOptions.pageNumber = page;
    this.appState.isLoading = true;
    this.service.listPageable(this.queryOptions).subscribe(
      (response: Page<Produto>) => {
        if (response) {
          this.mapearAcoes(response.content);
          this.totalRecords = response.totalElements;
        }
        this.appState.isLoading = false;
      },
      () => (this.appState.isLoading = false),
    );
  }

  onLoad(pageNumber: number): void {
    this.list(pageNumber);
  }

  onDelete(produto: Produto): void {}

  onAcaoClick(event: AcaoEvent): void {
    const actions = new Map<string, (produto: Produto) => void>();

    actions.get(event.acao)(event.produto);
  }


  onFilter(options: ListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    this.queryOptions.params = {
      ...this.queryOptions.params,
      postoId,
    };

    this.list();

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }
  }
}
