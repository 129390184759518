import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcompanhamentoClienteMainComponent } from './acompanhamento-cliente-main/acompanhamento-cliente-main.component';
import { GlobalModule } from 'src/app/global/global.module';
import { ClienteDetailComponent } from './cliente-detail/cliente-detail.component';

@NgModule({
  declarations: [AcompanhamentoClienteMainComponent, ClienteDetailComponent],
  imports: [CommonModule, GlobalModule],
})
export class AcompanhamentoClienteModule {}
