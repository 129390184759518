import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { Posto } from 'src/app/base/domain/posto.model';
import { PostoService } from 'src/app/base/services/posto.service';
import { ReturnAPI } from 'src/app/base/domain/return-api.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-global-filter',
  templateUrl: './global-filter.component.html',
  styleUrls: ['./global-filter.component.css'],
})
export class GlobalFilterComponent implements OnInit {
  @Input() filtros: string[];
  @Input() nomePlaceholder: string;
  @Input() situacoesPlaceholder: string;
  @Input() situacoes: string[];
  @Input() dataPlaceholder: string;

  @Output() postoSelecionadoClick = new EventEmitter<Posto>();

  postos: Posto[] = [];

  constructor(private postoService: PostoService) {}

  // Binding nomeInput
  private _nomeInput: string;
  @Output() nomeInputChange = new EventEmitter<string>();

  @Input()
  get nomeInput(): string {
    return this._nomeInput;
  }

  set nomeInput(val: string) {
    this._nomeInput = val;
    this.nomeInputChange.emit(this._nomeInput);
  }

  // Binding situacoesSelecionadas
  private _situacoesSelecionadas: string[];
  @Output() situacoesSelecionadasChange = new EventEmitter<string[]>();

  @Input()
  get situacoesSelecionadas(): string[] {
    return this._situacoesSelecionadas;
  }

  set situacoesSelecionadas(val: string[]) {
    this._situacoesSelecionadas = val;
    this.situacoesSelecionadasChange.emit(this._situacoesSelecionadas);
  }

  // Binding postoSelecionado
  private _postoSelecionado: Posto;
  @Output() postoSelecionadoChange = new EventEmitter<Posto>();

  @Input()
  get postoSelecionado(): Posto {
    return this._postoSelecionado;
  }

  set postoSelecionado(val: Posto) {
    this._postoSelecionado = val;
    this.postoSelecionadoChange.emit(this._postoSelecionado);
  }

  // Binding dataInicial
  private _dataInicial: Date;
  @Input() dataInicialLegend: string;
  @Output() dataInicialChange = new EventEmitter<Date>();
  @ViewChild('dataInicial', { read: MatInput }) dataInicialInput: MatInput;

  @Input()
  get dataInicial(): Date {
    return this._dataInicial;
  }

  set dataInicial(val: Date) {
    if (val === null && this.dataInicialInput) {
      this.dataInicialInput.value = '';
    }
    this._dataInicial = val;
    this.dataInicialChange.emit(this._dataInicial);
  }

  // Binding dataFinal
  private _dataFinal: Date;
  @Input() dataFinalLegend: string;
  @Output() dataFinalChange = new EventEmitter<Date>();
  @ViewChild('dataFinal', { read: MatInput }) dataFinalInput: MatInput;

  @Input()
  get dataFinal(): Date {
    return this._dataFinal;
  }

  set dataFinal(val: Date) {
    if (val === null && this.dataFinalInput) {
      this.dataFinalInput.value = '';
    }
    this._dataFinal = val;
    this.dataFinalChange.emit(this._dataFinal);
  }

  ngOnInit(): void {}

  postoSelecionadoEvent(posto: Posto): void {
    this.postoSelecionadoClick.emit(posto);
  }

  onPickerEvent(picker: 'inicial' | 'final', event: MatDatepickerInputEvent<Moment>): void {
    if (picker === 'inicial') {
      this.dataInicial = event.value?.toDate() || null;
    }
    if (picker === 'final') {
      this.dataFinal = event.value?.toDate() || null;
    }
  }
}
