import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Contrato } from '../../../domain/contrato.model';
import { ContratoService } from '../../../services/contrato.service';
import { Page, ReturnAPI } from '../../../domain/return-api.model';
import { ParcelaService } from 'src/app/base/services/parcela.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { Parcela } from 'src/app/base/domain/parcela.model';

@Component({
  selector: 'app-acompanhamento-contrato-detail',
  templateUrl: './acompanhamento-contrato-detail.component.html',
  styleUrls: ['./acompanhamento-contrato-detail.component.css'],
})
export class AcompanhamentoContratoDetailComponent implements OnInit {

  queryOptions = new QueryOptions({ pageSize: 25 });
  parcelas: Parcela[] = [];
  totalRecords = 0;
  loading = false;

  constructor(private route: ActivatedRoute, private parcelaService: ParcelaService) {
    if (this.route.snapshot.queryParamMap.has('numCliente') && this.route.snapshot.queryParamMap.has('data')) {
      this.queryOptions.params = {
        numCliente: this.route.snapshot.queryParamMap.get('numCliente'),
        dataInicial: this.route.snapshot.queryParamMap.get('data'),
      };
      this.listarParcelas(0);
    }
  }

  ngOnInit(): void {}

  listarParcelas(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber;
    this.loading = true;
    this.parcelaService.findAll(this.queryOptions).subscribe((response: Page<Parcela>) => {
      this.parcelas = response.content;
      this.totalRecords = response.totalElements;
      this.loading = false;
    });
  }

  onParcelasPage(page: number): void {
    this.listarParcelas(page);
  }
}
