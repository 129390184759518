import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from './components/components.module';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { TabViewModule } from 'primeng/tabview';
import { ConfirmacaoDialogComponent } from './components/confirmacao-dialog/confirmacao-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogService } from './services/dialog.service';
import { ContratoListComponent } from './lists/contrato-list/contrato-list.component';
import { ContratoInfoGeralComponent } from './widgets/contrato-info-geral/contrato-info-geral.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { PostoService } from '../base/services/posto.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GraficoVerticalComponent } from './charts/grafico-vertical/grafico-vertical.component';
import { ClienteListComponent } from './lists/cliente-list/cliente-list.component';
import { GlobalFilterComponent } from './widgets/global-filter/global-filter.component';
import { CounterCardComponent } from './widgets/counter-card/counter-card.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { DataValorFormComponent } from './forms/data-valor-form/data-valor-form.component';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { CheckboxSelectFormComponent } from './forms/checkbox-select-form/checkbox-select-form.component';
import { SelecionarProdutoFormComponent } from './forms/selecionar-produto-form/selecionar-produto-form.component';
import { UsuarioListComponent } from './lists/usuario-list/usuario-list.component';
import { UsuarioFormComponent } from './forms/usuario-form/usuario-form.component';
import { SelectFormComponent } from './forms/select-form/select-form.component';
import { ParcelaListComponent } from './lists/parcela-list/parcela-list.component';
import { MatTooltipModule } from '@angular/material/tooltip';

export const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: false,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
  nullable: true,
  min: 0,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL,
};

@NgModule({
  declarations: [
    ClienteListComponent,
    ContratoListComponent,
    ConfirmacaoDialogComponent,
    ContratoInfoGeralComponent,
    GraficoVerticalComponent,
    GlobalFilterComponent,
    CounterCardComponent,
    CheckboxSelectFormComponent,
    DataValorFormComponent,
    CheckboxSelectFormComponent,
    SelecionarProdutoFormComponent,
    UsuarioListComponent,
    UsuarioFormComponent,
    SelectFormComponent,
    ParcelaListComponent,
  ],
  providers: [
    DialogService,
    PostoService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD/MM/YYYY',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-br' },
  ],
  exports: [
    ClienteListComponent,
    ContratoListComponent,
    DialogService,
    ContratoInfoGeralComponent,
    GraficoVerticalComponent,
    GlobalFilterComponent,
    CounterCardComponent,
    CheckboxSelectFormComponent,
    DataValorFormComponent,
    CheckboxSelectFormComponent,
    SelecionarProdutoFormComponent,
    UsuarioListComponent,
    UsuarioFormComponent,
    SelectFormComponent,
    ParcelaListComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    ComponentsModule,
    TableModule,
    PaginatorModule,
    TabViewModule,
    MatMenuModule,
    MatDialogModule,
    MatSelectModule,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot({ validation: true }),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatTabsModule,
    MatTooltipModule
  ],
})
export class GlobalModule { }
