import { Component, OnInit, Input } from '@angular/core';
import { Contrato } from 'src/app/base/domain/contrato.model';
import { Cliente } from 'src/app/base/domain/cliente.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClienteService } from 'src/app/base/services/cliente.service';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ContratoService } from 'src/app/base/services/contrato.service';
import { DialogService } from 'src/app/global/services/dialog.service';
import { ContratoListFilterOptions } from 'src/app/global/lists/contrato-list/contrato-list.component';

@Component({
  selector: 'app-cliente-detail',
  templateUrl: './cliente-detail.component.html',
  styleUrls: ['./cliente-detail.component.css'],
})
export class ClienteDetailComponent implements OnInit {
  numCliente: string;
  cliente: Cliente;
  title = 'Contratos do Cliente';

  contratos: Contrato[] = [];
  tipoContas: string[] = [];

  queryOptions = new QueryOptions({ pageSize: 10 });
  totalRecords = 0;
  loading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private contratoService: ContratoService,
  ) {
    this.route.params.subscribe((params: Params) => {
      this.numCliente = params.id;
    });
  }

  ngOnInit(): void {
    this.queryOptions.params = {
      numCliente: this.numCliente || null,
    };
  }


  listarContratos(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.contratoService.findAll(this.queryOptions).subscribe((response: Page<Contrato>) => {
      this.contratos = response.content;
      this.loading = false;
    });
  }


  onContratosPage(page: number): void {
    this.listarContratos(page);
  }

  onDisclosure(contrato: Contrato): void {
    this.router.navigate(['base/contratos/acompanhamento-contrato-detail'], 
      {queryParams: {numCliente: contrato.numCliente, data: contrato.data}});
  }

}
