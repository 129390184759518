import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalModule } from 'src/app/global/global.module';
import { AcompanhamentoContratoMainComponent } from './acompanhamento-contrato-main/acompanhamento-contrato-main.component';
import { AcompanhamentoContratoDetailComponent } from './acompanhamento-contrato-detail/acompanhamento-contrato-detail.component';

@NgModule({
  declarations: [AcompanhamentoContratoMainComponent, AcompanhamentoContratoDetailComponent],
  imports: [CommonModule, GlobalModule],
})
export class AcompanhamentoContratoModule {}
