import { Component, OnInit } from '@angular/core';
import { Cliente } from '../../../domain/cliente.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ClienteListFilterOptions, ClienteAcaoEvent } from 'src/app/global/lists/cliente-list/cliente-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';
import { ClienteService } from '../../../services/cliente.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Page } from 'src/app/base/domain/return-api.model';

@Component({
  selector: 'app-acompanhamento-cliente-main',
  templateUrl: './acompanhamento-cliente-main.component.html',
  styleUrls: ['./acompanhamento-cliente-main.component.css'],
})
export class AcompanhamentoClienteMainComponent implements OnInit {

  clientes: Cliente[] = [];
  queryOptions = new QueryOptions({ pageSize: 25 });
  totalRecords = 0;
  loading = false;

  private postoSelecionado: number = null;

  constructor(
    public dialogService: DialogService,
    private clienteService: ClienteService,
    public dialog: MatDialog,
    private router: Router,
  ) {}

  ngOnInit(): void {}

  listarClientes(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber;
    this.loading = true;
    this.queryOptions.params = { ...this.queryOptions.params, joinRota: true };
    this.clienteService.findAll(this.queryOptions).subscribe((response: Page<Cliente>) => {
      this.clientes = response.content;
      this.totalRecords = response.totalElements;
      this.loading = false;
    });
  }

  onAcaoClick(event: ClienteAcaoEvent): void {
    const actions = new Map<string, (cliente: Cliente) => void>(); 

    actions.get(event.acao)(event.cliente);
  }

  onLoadClientePage(page: number): void {
    this.listarClientes(page);
  }

  onFilter(options: ClienteListFilterOptions): void {
    this.queryOptions.params = {
      nomeCliente: options.nomeCliente ? `${options.nomeCliente}` : null,
      numCliente: options.numCliente ? `${options.numCliente}` : null,
      apelidoCliente: options.apelidoCliente ? `${options.apelidoCliente}` : null,
      descricaoRota: options.descricaoRota || null,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
      cpf: options.cpf || null
    };
    this.listarClientes();
  }

  onDisclosure(cliente: Cliente): void {
    this.router.navigate(['base/clientes/detail/' + cliente.numCliente]);
  }
}
