import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { QueryOptions } from "src/app/global/domain/query.options";
import { ResourceService } from "src/app/global/services/resource.service";
import { WEB_API } from "src/app/global/services/web-api";
import { Parcela } from "../domain/parcela.model";
import { Page } from "../domain/return-api.model";
import { ParcelaSerializer } from "../serializables/parcela.serializer";

@Injectable()
export class ParcelaService extends ResourceService<Parcela> {
  static RESOURCE = 'parcela';

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${ParcelaService.RESOURCE}`, new ParcelaSerializer(Parcela));
  }

  findAll(queryOption: QueryOptions): Observable<Page<Parcela>> {
    return this.httpClient.get<Page<Parcela>>(
      `${WEB_API}/${ParcelaService.RESOURCE}?${queryOption.toQueryString()}`,
    );
  }
}