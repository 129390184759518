import { Component, OnInit } from '@angular/core';
import { Estado } from 'src/app/base/domain/estado.model';
import { QueryOptions } from '../../domain/query.options';
import { EstadoService } from 'src/app/base/services/estado.service';
import { Produto } from 'src/app/base/domain/produto.model';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-selecionar-produto-form',
  templateUrl: './selecionar-produto-form.component.html',
  styleUrls: ['./selecionar-produto-form.component.css'],
})
export class SelecionarProdutoFormComponent implements OnInit {
  estados: Estado[] = [];
  filteredEstados: Estado[] = [];
  estadoInput = '';
  queryOptions = new QueryOptions();

  produtos: Produto[] = [];

  produtoSelecionado: Produto;

  constructor(public dialogRef: MatDialogRef<SelecionarProdutoFormComponent>, private estadoService: EstadoService) {
    this.estadoService.list(this.queryOptions).subscribe((response: Estado[]) => {
      this.estados = response;
      this.filteredEstados = this.estados;
    });
  }

  ngOnInit(): void {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(this.produtoSelecionado);
  }

  onSelected(estadoNome: string): void {
    const estadoSelecionado = this.estados.find((estado: Estado) => estado.nome === estadoNome);
    this.produtos = estadoSelecionado.produtos;
  }

  doFilter(value: string): void {
    if (value === 'estado') {
      this.filteredEstados = this._filtrar(this.estadoInput, this.estados, 'nome');
    }
  }

  private _filtrar<T>(value: string, list: T[], field: string): T[] {
    const filterValue = value?.toLowerCase();
    return list.filter((e: T) => e[field].toLowerCase().indexOf(filterValue) === 0);
  }
}
